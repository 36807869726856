// extracted by mini-css-extract-plugin
export var children = "styles-module--children--f7050";
export var childrenStack = "styles-module--childrenStack--91ebc";
export var children_active = "styles-module--children_active--61580";
export var content = "styles-module--content--5cc3d";
export var gallery = "styles-module--gallery--abd44";
export var navigation = "styles-module--navigation--85d65";
export var navigationButton = "styles-module--navigationButton--42520";
export var navigationButton_next = "styles-module--navigationButton_next--ce2e5";
export var pagination = "styles-module--pagination--ec8f9";
export var paginationButton = "styles-module--paginationButton--b61c3";
export var paginationButton_active = "styles-module--paginationButton_active--55abd";
export var primaryImage = "styles-module--primaryImage--36184";
export var primaryImage_active = "styles-module--primaryImage_active--3291c";
export var primaryImages = "styles-module--primaryImages--2bd93";
export var scroller = "styles-module--scroller--309c8";
export var secondaryImage = "styles-module--secondaryImage--717b7";
export var secondaryImageStack = "styles-module--secondaryImageStack--e40ce";
export var secondaryImage_active = "styles-module--secondaryImage_active--129cd";