import React, { useState } from "react";
import { Link } from "gatsby-plugin-intl";
import { GatsbyImage } from "gatsby-plugin-image";
import { concatClassNames as cn } from "@system42/core";
import { Headline4Sans, Overline } from "@simplease/system42-theme-userbrain";

import * as styles from "./styles.module.css";

/**
 * @param {Array<Post<Object>>} posts
 * @property {string}  Post.primaryImage - Gatsby Image
 * @property {string}  Post.primaryImageAlt
 * @property {string}  Post.secondaryImage - Gatsby Image
 * @property {string}  Post.secondaryImageAlt
 * @property {string}  Post.title
 * @property {string}  Post.body
 * @property {string}  Post.caption
 * @property {string}  Post.url
 * @property {string | null}  Post.filterValue Hidden value used to filter by
 *
 * @param {string} filtersHeading Heading displayed above the filters
 *
 */
export function FilteredPostsGrid({ posts, filtersHeading, className }) {
  const [activeFilter, setActiveFilter] = useState(null);
  const filterValues = [...new Set(posts.map((post) => post.filterValue))];
  const isDisplayFilters = (filterValues.length > 1) | true;

  const filtersElement = (
    <div className={styles.filters}>
      <button
        onClick={() => setActiveFilter(null)}
        className={cn(
          styles.filter,
          (activeFilter?.length ?? 0) === 0 && styles.filter_active
        )}
      >
        All
      </button>
      {filterValues.map((value) => (
        <button
          onClick={() => setActiveFilter(value)}
          className={cn(
            styles.filter,
            activeFilter === value && styles.filter_active
          )}
          key={value}
        >
          {value}
        </button>
      ))}
    </div>
  );

  const postsElement = (
    <div className={styles.posts}>
      {posts
        .filter((post) =>
          activeFilter === null ? true : activeFilter === post.filterValue
        )
        .map((post, index) => (
          <Link
            key={index}
            to={`/customers/${post.url}`}
            className={styles.post}
          >
            <GatsbyImage
              className={styles.postPrimaryImage}
              image={post.primaryImage}
              alt={post.primaryImageAlt}
            />
            <div className={styles.postFooter}>
              <GatsbyImage
                className={styles.postSecondaryImage}
                image={post.secondaryImage}
                alt={post.secondaryImageAlt}
              />
              <div>
                <Headline4Sans customTag="h3">{post.title}</Headline4Sans>
                <div className={styles.postBody}>{post.body}</div>
                <div className={styles.postCaption}>{post.caption}</div>
              </div>
            </div>
          </Link>
        ))}
    </div>
  );

  return (
    <div className={cn(styles.filteredPostsGrid, className)}>
      {isDisplayFilters && filtersHeading && (
        <Overline className={styles.filtersHeading}>{filtersHeading}</Overline>
      )}
      {isDisplayFilters && filtersElement}
      {postsElement}
    </div>
  );
}
